.component {
  z-index: 0;
  position: relative;
  overflow: hidden;

  & > .heroLayout {
    z-index: -2;
    position: fixed;
    width: 100%;
    height: 100vh;
    height: 100lvh;
    top: 0;
    overflow: visible;
  }

  & > .contentBorderLayout {
    z-index: -1;
    width: 100%;
    height: 100vh;
    height: 100svh;
  }
}

.headingSection {
  text-align: center;
  position: relative;

  & > .headingLayout {
    position: absolute;
    top: -25rem;
    width: 100%;
  }
}

.componentHeading {
  color: var(--color-white);
  font-size: var(--font-size-120);
  font-size: var(--font-size-70-120);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-normal);
}
