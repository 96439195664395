.component {
  display: flex;
  flex-direction: column;
  padding: var(--size-100) 0 0;

  & > .heroContainer {
    @media (--viewport-md) {
      min-height: 100vh;
    }
  }

  & > .searchboxLayout {
    width: 100%;

    @media (--viewport-md) {
      width: 50%;
      margin: 0 auto;
    }

    @media (--viewport-lg) {
      width: 40%;
    }
  }
}

.heroContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .headingWithVideoAndTextLayout {
    width: 100%;
    margin-top: var(--size-300);

    @media (--viewport-md) {
      width: 80%;
      margin-top: unset;
    }
  }

  & > .heroImageLayout {
    position: absolute;
    width: 100%;
    height: 390px;

    @media (--viewport-md) {
      position: relative;
      width: 20%;
      height: auto;
    }
  }
}

.componentHeadingWithVideoAndText {
  display: flex;
  flex-direction: column;
  padding: 0 var(--size-32);

  @media (--viewport-md) {
    padding: 0;
  }

  & > .headingSection {
    height: 60%;
  }

  & > .videoAndTextWithLine {
    height: 40%;
  }
}

.componentHeroImage {
  display: flex;
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: contentReveal var(--duration-850) ease forwards;
  animation-delay: var(--duration-900);

  & > .imageContainer {
    position: absolute;
    width: 350px;
    top: 50%;
    right: -175px;

    @media (--viewport-md) {
      right: -135px;
    }
  }
}

.imageContainer {
  pointer-events: none;
  transform: translateY(-50%) scale(0.5);

  @media (--viewport-sm) {
    transform: translateY(-50%) scale(0.4);
  }

  @media (--viewport-md) {
    transform: translateY(-50%) scale(0.6);
  }

  @media (--viewport-lg) {
    transform: translateY(-50%) scale(0.8);
  }

  @media (--viewport-xl) {
    transform: translateY(-50%) scale(1);
  }
}

.headingSection {
  display: flex;
  position: relative;
  padding-bottom: var(--size-20);
  align-items: flex-end;

  @media (--viewport-md) {
    justify-content: flex-end;
    padding-right: var(--size-200);
    padding-left: var(--size-32);
  }

  & > .smallVideoContainer {
    position: absolute;
    top: -280px;
    right: 80px;
    width: var(--size-125);

    @media (--viewport-md) {
      width: var(--size-200);
      top: 0;
      right: clamp(-20px, 10%, -80px);
    }
  }
}

.smallVideoContainer {
  aspect-ratio: 4 / 6;
  object-fit: cover;
  opacity: 0;
  animation: contentReveal var(--duration-850) ease forwards;
  animation-delay: var(--duration-850);
  border-radius: var(--size-300);
  overflow: hidden;

  & > .videoLayout {
    height: 100%;
  }
}

.videoAndTextWithLine {
  display: flex;
  padding-top: var(--size-40);

  & > .videoSection {
    margin-top: var(--size-40);
    width: 65%;
  }

  & > .textWithLineSection {
    width: 100%;

    @media (--viewport-md) {
      width: 40%;
    }
  }

  & > * {
    height: 100%;
  }
}

.videoSection {
  opacity: 0;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 15%;
  animation: contentReveal var(--duration-850) ease forwards;
  animation-delay: var(--duration-850);

  @media (--viewport-md) {
    display: flex;
  }
}

.componentVideo {
  border-radius: var(--size-300);
  overflow: hidden;
}

.componentHeading {
  display: flex;
  flex-direction: column;
  gap: var(--size-4);
  font-size: var(--font-size-240);
  font-size: var(--font-size-70-240);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-heading);

  @media not (--viewport-md) {
    line-break: normal;
  }
}

.headingWhiteLine {
  color: var(--color-white);
  opacity: 0;
  animation: contentReveal var(--duration-850) ease forwards;
  animation-delay: var(--duration-500);
}

.headingBlueLine {
  opacity: 0;
  animation: contentReveal var(--duration-850) ease forwards;
  animation-delay: var(--duration-600);
}

.lineContainer {
  display: flex;
  justify-content: center;
  transform-origin: top;

  & > .lineLayout {
    width: var(--size-4);
    height: var(--size-300);

    @media (--viewport-md) {
      height: 455px;
    }
  }
}

.componentSearchbox {
  padding: var(--size-32) var(--size-32) var(--size-125);

  @media (--viewport-md) {
    padding: var(--size-200) 0;
  }

  & > .jobsLinkLayout {
    margin-top: var(--size-36);
  }
}

.searchboxForm {
  position: relative;

  & > .searchboxInput {
    width: 100%;
  }

  & > .searchButton {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
  }
}

.searchboxInput {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-70);
  font-weight: var(--font-weight-400);
  color: var(--color-white);
  border-bottom: var(--size-4) solid var(--color-blue-900);
  transition: border-bottom-color var(--duration-150) ease;
  padding-right: var(--size-100);

  &::placeholder {
    opacity: 1;
    color: var(--color-white);
    transition: opacity var(--duration-150) ease;
  }

  &:focus {
    outline: none;
    border-bottom-color: var(--color-white);

    &::placeholder {
      opacity: 0.4;
    }
  }
}

.searchButton {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  font-size: var(--font-size-32-70);

  & > .iconLayout {
    width: 1em;
    height: 1em;
  }
}

.componentIntroTextWithLine {
  display: flex;
  color: var(--color-white);
  gap: var(--size-32);

  @media (--viewport-md) {
    gap: var(--size-64);
  }
}

.introText {
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-20);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-text);
  white-space: normal;
  opacity: 0;
  animation: contentReveal var(--duration-850) ease forwards;
  animation-delay: var(--duration-700);
}

.player {
  pointer-events: none;

  & > * {
    /* stylelint-disable-next-line klm/layout-related-properties */
    object-fit: cover;
  }
}

@keyframes floatingImageReveal {
  0% {
    opacity: 0;
    transform: translateY(var(--size-40));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes contentReveal {
  0% {
    opacity: 0;
    transform: translateY(var(--size-20));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
