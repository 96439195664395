.component {
  position: relative;
  overflow: hidden;

  & > .heroContainer {
    width: 100%;
    height: 100vh;
  }
}

.heroContainer {
  position: relative;
  z-index: 0;
  overflow: hidden;

  & > .heroVideo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.heroVideo {
  object-fit: cover;

  & > .videoLayout {
    width: 100vw;
    height: 100lvh;
  }
}

.componentVideo {
  overflow: hidden;
}

.player {
  pointer-events: none;

  & > * {
    /* stylelint-disable-next-line klm/layout-related-properties */
    object-fit: cover;
  }
}
