.component {
  display: flex;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: flex-end;
  align-items: center;
  transition: background-image 0.3s ease-in-out;
  max-width: 1600px;
  margin: 0 auto;

  @media (min-width: 1600px) {
    border-radius: var(--radius-10);
  }

  & > .containerLayout {
    width: 50%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--size-100) 0;

  & > .buttonLayout {
    width: 100%;
    height: max-content;
    margin-bottom: var(--size-12);
  }
}

.componentButton {
  --transform-icon: 0;

  pointer-events: auto;
  display: flex;
  gap: var(--size-8);
  align-items: center;
  justify-content: space-between;
  padding: var(--size-16) var(--size-32);
  color: var(--color-white);
  border: 2px solid var(--color-white);
  border-radius: var(--radius-100);
  background-color: transparent;

  &:hover {
    --transform-icon: var(--size-12);

    background-color: var(--color-white);
    color: var(--color-blue-900);
  }

  & > .iconContainer {
    height: var(--size-40);
  }
}

.buttonLabel {
  font-size: var(--font-size-22-32);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-block);
}

.iconContainer {
  transform: translateX(var(--transform-icon));
  transition: transform var(--duration-600) var(--ease-out-expo);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}
