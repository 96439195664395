.component {
  padding: var(--size-80) 0;

  @media (--viewport-lg) {
    padding: var(--size-150) 0 var(--size-125);
  }
}

.componentWithoutBottomPadding {
  padding-top: var(--size-150);

  @media (--viewport-lg) {
    padding-top: var(--size-80);
  }
}

.componentBase {
  display: flex;
  flex-direction: column;
  gap: var(--size-125);
  background-color: var(--color-white);

  @media (--viewport-lg) {
    gap: var(--size-200);
  }
}

.componentCampaign {
  padding: var(--size-40) 0;

  @media (--viewport-lg) {
    padding: var(--size-64) 0 var(--size-52);
  }
}

.componentBaseCampaign {
  display: flex;
  flex-direction: column;
  gap: var(--size-80);
  background-color: var(--color-white);

  @media (--viewport-lg) {
    gap: var(--size-150);
  }
}
