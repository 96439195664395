.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-125);
  overflow: hidden;
}

.slider {
  display: flex;
  cursor: grab;
  overflow: visible !important;

  &:active {
    cursor: grabbing;
  }

  & > .slide {
    height: auto;
  }
}

.slide {
  overflow: visible !important;

  & > .slideLayout {
    height: 100%;
  }

  & > .slideWrapper {
    width: 100%;
    height: 100%;
  }
}

.slideWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.componentSlide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .imageWrapper {
    width: 100%;
    height: 100%;
  }
}

.imageWrapper {
  position: relative;

  & > .buttonLayout {
    position: absolute;
    bottom: var(--size-48);
    width: 80%;
    left: 50%;
  }
}

.componentButton {
  --transform-icon: 0;

  pointer-events: auto;
  transform: translateX(-50%);
  display: flex;
  gap: var(--size-8);
  align-items: center;
  justify-content: space-between;
  padding: var(--size-8) var(--size-24);
  color: var(--color-white);
  border: 2px solid var(--color-white);
  border-radius: var(--radius-100);
  background-color: transparent;

  &:hover {
    --transform-icon: var(--size-12);

    background-color: var(--color-white);
    color: var(--color-blue-900);
  }

  & > .iconContainer {
    height: var(--size-40);
  }
}

.iconContainer {
  transform: translateX(var(--transform-icon));
  transition: transform var(--duration-600) var(--ease-out-expo);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

.buttonLabel {
  font-size: var(--font-size-22-32);
  font-weight: var(--font-weight-300);
  line-height: var(--line-height-block);
}
